import revive_payload_client_8Ex23hNlcA from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_09X9pvwqdO from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RWVqsWGTR5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Zf7P1adCx3 from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.0_vite@5.4.8_@types+node@20.17.6_terser@5.31.0__vue@3.4.2_atmcynstxqzgf7qvfflcdbdd3u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import check_outdated_build_client_PPgQdJJScp from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_fTbcFGOhX5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_IhlMIdmnI7 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.24.0_vite@5.4.8_@types+node@20.17.6_terser@5.31.0__sayl5veas6dtx4q66zusep3dia/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_wTE8sBQUkR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.24.0_vite@5.4.8_@types+node@20.17.6_terser@5.31.0__sayl5veas6dtx4q66zusep3dia/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_ZpbweroCpP from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.6_terser@5.31.0__vue@3._ko4ik2m5mteeegfcvoo7yqe4ve/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_cndl8zdmHC from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.6_terser@5.31.0__vue@3._ko4ik2m5mteeegfcvoo7yqe4ve/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_9zgXtpYBW2 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_y5DAEgdrL4 from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_eebkvqv3tt5t5tau7kjqaxi7zu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_skW6uY3AFT from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Qv0JdSYWMW from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_j5EaroIQf0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.6_@unocss+reset@_uccmadoa534mvu467k5a7bg52u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/apps/website/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_TVShkJjiHP from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.3_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_8Ex23hNlcA,
  unhead_09X9pvwqdO,
  router_RWVqsWGTR5,
  _0_siteConfig_Zf7P1adCx3,
  check_outdated_build_client_PPgQdJJScp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fTbcFGOhX5,
  titles_IhlMIdmnI7,
  defaultsWaitI18n_wTE8sBQUkR,
  siteConfig_ZpbweroCpP,
  inferSeoMetaPlugin_cndl8zdmHC,
  plugin_client_9zgXtpYBW2,
  plugin_y5DAEgdrL4,
  switch_locale_path_ssr_skW6uY3AFT,
  i18n_Qv0JdSYWMW,
  chunk_reload_client_j5EaroIQf0,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_TVShkJjiHP
]