<template>
    <div class="fixed bottom-0 left-0 right-0 z-30 flex w-full justify-end">
        <div
            class="mx-4 mb-2 flex w-full max-w-[400px] flex-col gap-4 rounded-lg bg-gray-800 p-5 shadow-lg"
        >
            <p class="flex items-center gap-2 text-lg font-bold text-white">
                <img :src="cookieSVG" class="h-6" height="24" width="24" />
                {{ t('title') }}
            </p>

            <i18n-t
                keypath="description"
                class="text-sm leading-5 text-gray-300"
                tag="p"
            >
                <template #link>
                    <a
                        href="/privacy"
                        class="text-blue-400 hover:underline"
                        target="_blank"
                    >
                        {{ t('here') }}
                    </a>
                </template>
            </i18n-t>

            <div class="flex w-full gap-4">
                <!-- Accept -->
                <BaseButton
                    color="blue"
                    class="flex-1 rounded-md border-none"
                    @click="emit('toggle', true)"
                >
                    {{ t('accept') }}
                </BaseButton>

                <!-- Decline -->
                <BaseButton
                    variant="outline"
                    class="!ring-0"
                    @click="emit('toggle', false)"
                >
                    <span class="rounded-md text-gray-400">
                        {{ t('decline') }}
                    </span>
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const cookieSVG = '/icons/cookie.svg'

const emit = defineEmits<{
    toggle: [value: boolean]
}>()
</script>

<i18n lang="yaml">
bg:
    title: 'Бисквитки'
    description: 'Този уебсайт използва бисквитки, за да подобри Вашето потребителско изживяване и да анализира използването на сайта. Можете да приемете или откажете употребата на бисквитки. Повече информация {link}'
    accept: 'Приемам'
    decline: 'Отхвърлям'
    here: 'тук'

en:
    title: 'Cookies'
    description: 'This website uses cookies to improve your user experience and to analyze site usage. You can accept or decline the use of cookies. More information {link}'
    accept: 'Accept'
    decline: 'Decline'
    here: 'here'
</i18n>
