<template>
    <Html :lang="locale" class="h-full scroll-smooth bg-white font-normal" />

    <Body class="h-full" />

    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <AppPrivacyPolicyBanner
        v-if="showPrivacyPolicyBanner"
        @toggle="clickPrivacyPolicyBanner"
    />

    
</template>

<script setup lang="ts">
 import.meta.prerender && defineOgImage({
    url: '/og-image.png',
})

const { locale } = useI18n()

const isProd = process.env.NODE_ENV === 'production'

const showPrivacyPolicyBanner = ref(false)

const privacyConcentAgreed = useCookie<boolean>(
    'efirma-privacy-agreed',
    // 1 year
    {
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        domain: isProd ? '.efirma.bg' : 'localhost', // Share across all subdomains
        path: '/', // Accessible site-wide
        secure: isProd, // Only secure in production (HTTPS)
        sameSite: 'lax', // Protect from CSRF attacks
    },
)

onMounted(() => {
    if (privacyConcentAgreed.value === undefined) {
        showPrivacyPolicyBanner.value = true
    }
})

function clickPrivacyPolicyBanner(value: boolean) {
    privacyConcentAgreed.value = value
    showPrivacyPolicyBanner.value = false
}
</script>

<style>
:root {
    --header-height: 64px;
}
</style>
